<!-- Autorización Capital Insuficiente -->

<template>
  <div class="ciacM" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      persistent>
      
        <template v-slot:controles="{ }">
       
          <div>
            <div class="cabecera">     
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">

              <!-- Controles del Mto -->
              <div class="contenedor" style="width:750px;max-width:1240px">                  
                <div class="conflex" style="padding-bottom:10px">
                  <v-btn                
                    v-bind="$cfe.btn.buscaTexto"
                    @click="sendAutorizacion(1)">
                      <v-icon left>{{'mdi-check'}}</v-icon>
                        AUTORIZO
                  </v-btn>

                  <v-btn                
                    v-bind="$cfe.btn.buscaTexto"
                    style="margin-left:15px"
                    @click="sendAutorizacion(0)">
                      <v-icon left>{{'mdi-close'}}</v-icon>
                       NO AUTORIZO
                  </v-btn>
                </div>
               
                <v-textarea
                  v-bind="$textarea"
                  v-model="ct.txt[2]"
                  :label="ct.txt[1]"
                  no-resize>
                </v-textarea>                
              </div>
            </v-sheet> 
          </div>
        </template>
    </dualTemplate>  
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";  
  import baseHeader from "@/base/baseHeader";
  import dualTemplate from "@/components/dualTemplate";
        
  export default {
    mixins: [mixM],
    components: { baseHeader, dualTemplate },
    props: {     
      schema: { type:Object, default:()=> {}},       
    },

    data() {
      return {
        stIni: {
          api: "autorizacionCIM",
          name:"autorizacionCIM",
          titulo:"Autorización Capital Insuficiente (JZ)",
          recordAccess:"masterStore",
          masterStore:this.masterStore,
          mView:'',
          pView:[]
        },

        /* schema: {
          ctrls: {
            txt: { f:'txt', label:'Estas observaciones junto a los datos del Expediente serán remitidas al Centro Tramitador', value:""}
          }
        }, */
       
     
      };
    },



    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

      },


      // defino todas las API calls
      iniDataApi() {
        this.apiArgs= {
          sch: null,
          autorizacion: ["", "autorizacionJZ", { exp_id: 0, aut:0, txt:'' }, "expediente"],          
        }
      },
      

      //
      async sendAutorizacion(aut) {
        let param= { apiArg:this.apiArgs.autorizacion, args:{ 
          exp_id:this.ID, 
          aut, 
          txt:this.ct.txt[2] 
        }};

        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult sendAutorizacion: ', apiResult);
       
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // paso los datos de expedientes a ctrls
        this.record2ctrl(apiResult.r[0], this.ct);
      }
    },


    computed: {
      sch() {
        return {
          txt: [ 'txt', 'Estas observaciones junto a los datos del Expediente serán remitidas al Centro Tramitador', ""]
        }
      }
    }

  };
</script>
